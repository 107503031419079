@import '../../../../Content/WebGiving/webgiving_theme/variables.less';
@import '../../../../Content/WebGiving/bootstrap-variables.less';

.content-wrapper {
	margin: 30px 40px;
}

.subHeading {
	font-size: @font-size-base-plus;
	margin-bottom: 40px;
}

.secondary-cta {
	margin-top: 10px;

	a {
		text-decoration: none;
	}
}

.thank-you-message {
	font-style: italic;
	margin-bottom: 40px;

	&:before {
		content: open-quote;
	}
	&:after {
		content: close-quote;
	}
}

.pledge-success-icon {
	margin: auto;
	border-radius: @border-radius-small;
	color: @gray-mid;

	svg {
		width: 38px;
		height: 39px;
	}
}
