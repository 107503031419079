@import '../../../../Content/WebGiving/webgiving_theme/variables.less';
@import '../../../../Content/WebGiving/mixins/box-shadow.less';

.container {
	display: flex;
	flex-direction: column;
	margin: 36px 0 @spacing-vertical-small;

	@media @small {
		margin-bottom: 0;
	}
}

.title {
	font-size: @font-size-base;
	font-weight: bold;
	text-align: center;
	padding: @padding-large-vertical;
	background-color: @gray-lighter;
}

.info {
	font-size: @font-size-base-minus;
	font-weight: normal;
	text-align: center;
	padding-top: 12px;
    color: @gray-mid;
}

.rows {
	padding: 24px;
	background-color: @color-form-alt;
}

.row {
	display: flex;
	justify-content: space-between;
	font-size: @font-size-base-plus;

	& + & {
		margin-top: 12px;
	}

	@media @small {
		flex-direction: column;
	}
}
