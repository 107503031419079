@import '../../../Content/WebGiving/webgiving_theme/variables.less';
@import '../../../Content/WebGiving/mixins/mixins.less';

@overlay-transition-props: @transition-duration ease-in-out;

:export {
	transitionDurationMs: @transition-duration-ms;
	transitionDurationLongMs: @transition-duration-long-ms;
	fixedHeaderPlusLabelHeight: @main-header-height + @label-min-height;
}

.main,
.header {
	overflow-x: hidden;
	transition: transform @overlay-transition-props;

	&.eclipsed {
		@media @small {
			transform: translate(-@account-mobile-width, 0);
		}
	}
}

.main {
	position: relative;
	background: @main-background;
	min-height: 100vh;
	width: 100%;
	padding-top: @main-header-height;

	@media @small {
		padding-top: @main-header-height-small;

		&.logged-in {
			padding-top: @main-header-height-small-loggedin;
		}
	}
	.overlay(loading, 10000, @transition-duration, @transition-duration-long);

	&.loading:before {
		content: '';
		top: 0;
		left: 0;
		right: 0;
		height: 5px;
		width: 100%;
		z-index: 10001;
		display: block;
		position: fixed;
		background: currentColor;
		animation: animate-progress-bar 2s cubic-bezier(0.755, 0.05, 0.855, 0.06) infinite;
		animation-delay: @transition-duration-long;
		transform-origin: left;
		transform: scaleX(0);
		visibility: visible;
	}

	@keyframes animate-progress-bar {
		0% {
			transform: translateX(0) scaleX(0);
		}
		50% {
			transform: translateX(25%) scaleX(0.75);
		}
		100% {
			transform: translateX(100%) scaleX(0);
		}
	}
}

.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: @zindex-navbar-fixed;
	height: @main-header-height;
	transition: ~'background-color @{transition-duration-long} ease-in-out';

	@media @small {
		height: @main-header-height-small;

		&.logged-in {
			height: @main-header-height-small-loggedin;
		}
	}
}

.container {
	padding-top: @spacing-vertical;
	max-width: @main-container-max-width;
	position: relative;
	z-index: @zindex-hero-image + 1;

	@media @small {
		padding: 0;
		max-width: none;
	}
}

.page {
	display: none;

	&-visible {
		display: block;
	}

	.overlay(loading, @zindex-loading-overlay, @transition-duration, @transition-duration);

	&.loading &-footer {
		position: relative;
		z-index: @zindex-loading-overlay + 1;
		pointer-events: all;
	}
}

.footer {
	font-size: 12px;
	margin-top: @spacing-vertical-large;
	padding-bottom: @spacing-vertical;
	transform: translateY(0);
	transition: transform @transition-duration-long ease-in-out;
	position: relative;
	z-index: @zindex-hero-image + 1;
}

.footer-container {
	max-width: @main-container-max-width;
}

.footer-link {
	color: @text-color;
	font-weight: 500;

	&:hover,
	&:active,
	&:focus {
		color: @gray-darker;
	}
}

.pushpay-info-wrapper {
	display: flex;
	align-items: center;

	@media @small {
		flex-direction: column;
		.component {
			margin: 10px 0;
		}
	}
}

.pushpay-logo-wrapper {
	flex: auto;
	line-height: 0;
}

.info {
	display: flex;
	align-items: center;
	flex: none;
	@media @small {
		flex-direction: column;
	}
}

.pushpay-logo {
	color: @gray-light;
}

.org-info {
	display: flex;
	flex-direction: row;

	@media @small {
		flex-direction: column;
	}
}

.separator {
	visibility: visible;
	margin: 0 10px;
	color: @gray-light;

	@media @small {
		display: none;
	}
}

.horizontal-separator {
	margin: 12px 0;
}
