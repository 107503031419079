@import '../../../../Content/WebGiving/webgiving_theme/variables.less';
@import '../../../../Content/WebGiving/mixins/box-shadow.less';

@page-give-top-offset: -120px;

@amount-decorator-dollar-size: 24px;

@transition-target: 250ms;

@media @desktop, @tablet {
	.mobile-only {
		display: none !important;
	}
}

@media @small {
	.desktop-only {
		display: none !important;
	}
	.noBorder {
		border: none;
	}
	.bgWhite {
		background-color: @body-bg;
	}
}

:export {
	pageGiveTopOffset: @page-give-top-offset;
}

.panel,
.footer {
	transform: translateY(@page-give-top-offset);

	@media @small {
		transform: translateY(0);
	}
}

.header {
	position: absolute;
	top: -(@main-banner-height + @page-give-top-offset + @spacing-vertical);
	left: 0;
	width: 100%;
	color: @body-bg;
	text-align: center;
	padding: 44px 0 0 0;

	@media @small {
		top: -(@main-banner-height-small + @spacing-vertical / 2);
		padding-left: @panel-body-padding;
		padding-right: @panel-body-padding;
	}

	&--has-enhanced-campaign-name {
		padding-top: 29px;

		@media @small {
			padding-top: 18px;
		}
	}
}

.heading {
	margin-bottom: 22px;
	margin-top: 0;

	@media @small {
		margin-bottom: 12px;
	}
}

.pledge-title {
	font-size: 32px;
	line-height: 1.3125;
	letter-spacing: 0.5px;

	@media @small {
		font-size: 22px;
		line-height: 36px;
		margin-bottom: 0px;
	}
}

.pledge-subtitle {
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0 0 10px 0;

	@media @small {
		font-size: 16px;
		line-height: 20px;
		white-space: normal;
		word-break: break-word;
	}
}

.form-wrapper {
	padding-left: 0;
	padding-right: 0;

	@media @small {
		margin-left: 10px;
		margin-right: 10px;
	}
}

.header-old {
	margin: -@panel-body-padding;

	@media @small {
		margin: -@panel-body-padding-mobile;
		margin-top: -35px;
	}
}

.sub-heading {
	text-transform: uppercase;
	color: @gray-darker;
	font-family: @font-family-base;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
}

.pledge-input-field {
	label {
		color: @gray-darker;
		font-family: @font-family-sans-serif;
		font-size: 16px;
		font-weight: 600;
		line-height: 30px;
	}
	input {
		box-sizing: border-box;
		height: 45px;
		border: 1px solid @input-border;
		border-radius: @border-radius-small;
		background-color: @body-bg;
		padding-left: 65px;
	}

	.floating-label-container {
		position: relative;
	}

	.floating-label {
		position: absolute;
		height: 45px;
		padding: 11px 20px 0 20px;
		border-right: 1px solid @input-border;

		color: @text-color;
		font-family: @font-family-sans-serif;
		font-size: 17px;
		line-height: 22px;
	}
}

.enter-pledge-content {
	padding-top: @panel-body-padding;
	margin-top: @panel-body-padding;
	margin-left: -26px;
	margin-right: -26px;

	@media @small {
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 0;
		margin-top: 0;
		margin-left: -20px;
		margin-right: -20px;
	}
}

.enter-pledge-body-text {
	p {
		color: @gray-darker;
		font-family: @font-family-sans-serif;
		font-size: 16px;
		line-height: 25px;

		&.blurb-title {
			font-size: 24px;
			line-height: 29px;
			margin-bottom: 30px;

			@media @small {
				font-size: 20px;
				font-weight: 600;
				margin-bottom: 10px;
			}
		}
	}

	@media @tablet {
		padding-right: 40px;
	}

	@media @small {
		padding: 10px 20px 30px;
		background-color: @body-bg;
	}
}

.enter-pledge-footer {
	button[type='submit'] {
		width: 100% !important;
	}
}

.amount {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	transition: @transition-target;
	height: @amount-large-font-size;
	@media @small {
		margin: 0 -10px;
		height: @amount-mobile-large-font-size + 16px;
		&.medium {
			height: @amount-mobile-large-font-size;
		}
	}
}

.error-icon {
	margin-right: 10px;
}

.decorator {
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	justify-content: center;
	display: flex;
	line-height: 1;
	user-select: none;
}

.amount-input-wrapper {
	border-bottom: 1px solid white;
	position: relative;
	z-index: 0;
	line-height: 1;
	&.zero {
		border-bottom: 1px solid @white-50;
	}
}

.dollar {
	font-size: @amount-decorator-dollar-size;
	vertical-align: super;
	position: absolute;
	left: -@amount-decorator-dollar-size;
	text-align: center;
	display: inline-block;
	width: @amount-decorator-dollar-size;
}

.underline {
	color: transparent;
	user-select: none;
	&::selection {
		color: rgba(0, 0, 0, 0);
	}
}

.amount-validation-error {
	position: relative;
	margin-top: 20px;
	font-size: @font-size-small;
	font-weight: 400;
	line-height: 1.5;
	color: @color-red-validation;
	padding: 6px 10px;
	background-color: white;
	border-radius: @border-radius-small;
	.box-shadow-small;
	display: inline-flex;
	text-align: left;
	align-items: center;
	z-index: @zindex-popover;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 12px;
		height: 12px;
		transform: rotate(45deg);
		top: -6px;
		background-color: white;
		left: ~'calc(50% - 8px)';
	}

	// This allows IE10-xs to try to word wrap, it's not perfect but at least it does it.
	// Not that people should be using an IE10-xs brower
	.error-content {
		width: 100%;
	}
}

.input {
	font-weight: 200;
	border: 0;
	line-height: 1;
	width: 100%;
	text-align: center;
	z-index: 1;
	position: relative;
	background: transparent;
	padding: 0;

	&:focus {
		outline: none;
	}
}
