@import '../../../../Content/PushpayWeb/Css/flexbox';
@import '../../../../Content/WebGiving/bootstrap-variables';

p {
	&.topLabel {
		font-size: @font-size-base-plus;
		.justify-content-center();
	}

	&.bottomLabel {
		font-size: @font-size-base-plus;
		.justify-content-center();
		margin-top: 10px;
	}
}

.edit-link {
	font-size: @font-size-h6;
	color: @gray-dark;
	margin-top: 17px;
	.justify-content-center();

	@media @small {
		font-size: @font-size-small;
	}
}

.edit-icon {
	margin-right: 10px;
	vertical-align: middle;
}
