@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.security-code-field {
	position: relative;
}

.need-help {
	position: absolute;
	top: 0;
	right: floor((@grid-gutter-width / 2));

	@media @small {
		position: static;
		margin-top: @spacing-base;
	}
}

.remember {
	display: flex;
	justify-content: center;
}

.info-icon-offset {
	margin-top: -4px;
}

.mobile-number {
	white-space: nowrap;
}

.resend-code {
	white-space: nowrap;
}
