@import '~@pushpay/theming/lib/esm/giving-theme.less';

@import '../../../../../Content/WebGiving/webgiving_theme/variables.less';

.title {
	margin: 0;
	text-align: center;
}

.description {
	margin-top: @theme_spacing_medium;
}

.pledge-type {
	margin-top: @theme_spacing_xlarge;
}

.no-options {
	margin-top: @spacing-vertical;
}

@media not @small {
	.recurring-dates {
		justify-content: space-between;
		display: flex;
	}

	.recurring-start {
		width: 100%;
		margin-right: 8px;
	}

	.recurring-end {
		margin-left: 8px;
		width: 100%;
	}
}

.info {
	font-size: @font-size-base-minus;
	font-weight: normal;
    color: @gray-mid;
}

