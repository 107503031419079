@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

.heading-wrapper {
	margin: 20px 40px 0 40px;
}

.pledge-icon {
	margin: auto;
	border-radius: @border-radius-small;
	color: @gray-mid;

	svg {
		width: 41px;
		height: 38px;
	}
}

.secondary-cta {
	margin-top: 10px;

	a {
		text-decoration: none;
	}
}

.recurring-summary {
	padding-bottom: 20px;
	margin: 0;
}
