@import '../../../../Content/WebGiving/webgiving_theme/variables.less';

@header-text-color: @navbar-default-color;

@merchant-logo-border: 3px;
@merchant-logo-margin: 13px;
@merchant-logo-margin-small: 9px;

@enhanced-logo-height: 40px;
@enhanced-logo-height-small: 27px;

.merchant-logo {
	width: @main-header-height - @merchant-logo-margin*2 + @merchant-logo-border*2;
	height: @main-header-height - @merchant-logo-margin*2 + @merchant-logo-border*2;
	margin: @merchant-logo-margin - @merchant-logo-border;
	overflow: hidden;
	flex: none;
	background: white;
	border: @merchant-logo-border solid white;
	border-radius: @border-radius-small;

	@media @small {
		width: @main-header-height-small - @merchant-logo-margin-small*2 + @merchant-logo-border*2;
		height: @main-header-height-small - @merchant-logo-margin-small*2 + @merchant-logo-border*2;
		margin: @merchant-logo-margin-small - @merchant-logo-border;
	}
}

.enhanced-logo {
	height: @enhanced-logo-height;
	margin: @merchant-logo-margin - @merchant-logo-border;
	overflow: hidden;
	flex: none;

	@media @small {
		height: @enhanced-logo-height-small;
	}
}

.merchant-name {
	flex: auto;
	font-size: @font-size-base-plus;
	font-weight: 700;
	margin-left: 7.5px;
	letter-spacing: 0.03em;

	@media @small {
		margin-left: 4px;
		font-size: @font-size-small;
		line-height: 16px;
	}
}

.header-container {
	@media @small {
		padding: 0;
	}
}

.header-background {
	transition: ~'background-color @{transition-duration-long} ease-in-out';
}

.salutation {
	color: @header-text-color;
	text-align: center;
	font-size: 12px;
	height: @main-header-salutation-height;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: ~'background-color @{transition-duration-long} ease-in-out';

	@media not @small {
		display: none;
		margin-top: -4px;
	}
}

.merchant-header {
	color: @header-text-color;
	display: flex;

	@media @small {
		height: @main-header-height-small;
	}
}

.merchant-details-wrapper,
.enhanced-details-wrapper {
	flex: auto;
	display: flex;
	align-items: center;
}

.enhanced-details-wrapper {
	@media @small {
		display: block;
		margin-right: -(@main-header-account-button-width-small);
	}
}
