@import '../../../../Content/WebGiving/webgiving_theme/variables.less';
@import '../../../../Content/WebGiving/mixins/box-shadow.less';

@header-text-color: @navbar-default-color;
:export {
	transitionDurationMs: @transition-duration-ms;
}

@account-details-transition: opacity @transition-duration ease-in-out;
@account-details-transition-small: transform @transition-duration ease-in-out;

@account-focus-ring-extra-spacing: 5px;

.horizontal-divider {
	margin: @spacing-base -20px;

	@media @small {
		margin-left: 0;
		margin-right: 0;
		display: block;
	}
}

.container {
	position: relative;
	flex: none;
	flex-direction: column;
	display: flex;
	height: @main-header-height;
	justify-content: center;

	@media @small {
		position: inherit;
		height: @main-header-height-small;
		width: @main-header-height-small;
		overflow: hidden;
	}
}

@button-height: 40px;
@button-border-width: 1px;
@button-padding-vertical: @spacing-base*2;
@button-padding-horizontal: @grid-gutter-width;

.button {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	color: @header-text-color;
	text-decoration: none;
	background: transparent;
	border: none;
	height: @button-height;
	padding: @button-padding-vertical @button-padding-horizontal;
	border-radius: @button-height;
	border: 1px transparent solid;

	@media @small {
		line-height: 0;
		flex-direction: column;
		width: @button-height;
		height: @button-height;
		padding: 1px 6px;
	}

	&:hover,
	&:focus {
		color: @header-text-color;
		border-color: rgba(255, 255, 255, 0.8);
	}
}

.panel {
	position: fixed;
	z-index: @zindex-popup;
	top: @main-header-height;
	right: 0;
	width: @account-desktop-width;
	color: @text-muted;
	background-color: @color-form;
	padding: @grid-gutter-width @grid-gutter-width @spacing-base;

	@media screen and (min-width: @screen-sm-min) {
		.box-shadow-small;
		border-bottom-left-radius: @border-radius-small;
		border-bottom-right-radius: @border-radius-small;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			background-color: @color-form;
			display: block;
			width: 10px;
			height: 10px;
			right: 7px;
			transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
		}

		&-enter {
			opacity: 0;
		}

		&-enter-active {
			opacity: 1;
			transition: @account-details-transition;
		}

		&-exit-active {
			opacity: 0;
			transition: @account-details-transition;
		}
	}

	@media @small {
		position: fixed;
		top: 0;
		right: 0;
		width: @account-mobile-width;
		height: 100%;
		background-color: white;

		&-enter {
			transform: translateX(@account-mobile-width);
		}

		&-enter-active {
			transform: translateX(0);
			transition: @account-details-transition-small;
		}

		&-exit-active {
			transform: translateX(@account-mobile-width);
			transition: @account-details-transition-small;
		}
	}
}

.title {
	margin-top: 0;
	font-size: @font-size-base;

	@media @small {
		font-size: 22px;
		line-height: 28px;
	}
}

.close {
	cursor: pointer;
	border: 0;
	background: 0;
	padding: 0;
	line-height: 0;

	@media @small {
		top: 0;
		right: 0;
		margin: 10px;
		position: absolute;
	}
}

.salutation-small {
	font-size: @font-size-base-minus;

	@media @small {
		font-size: @font-size-small-minus;
		line-height: 1;
	}
}

.logo {
	padding-left: 10px;
}

.logo-small {
	display: none;
	@media @small {
		text-align: right;
		width: 100%;
		display: inline-block;
	}
}

.manage {
	margin-bottom: @spacing-base;

	&:focus {
		margin-left: -(@account-focus-ring-extra-spacing);
		padding-left: @account-focus-ring-extra-spacing;
	}
}

.signout {
	margin: @spacing-base 0;
}
